
import { Component, Vue } from 'vue-property-decorator';

@Component({
    name: 'UsageGuid',
})
export default class UsageGuid extends Vue {
    private guidList: any = []; // 指南列表

    private created() {
        this.getGuidList();
    }
    private getGuidList() {
        this.$httpService.getData({
            menuPath: this.$route.name,
            pageNo: 1,
            pageSize: 1000,
        }, '/apiProxy/api/frontend/menu/guides').then((res: any) => {
            this.guidList = res.list;
        });
    }
    private openLink(link: string) {
        window.open(link , '_blank');
    }
}
