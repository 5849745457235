
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import UsageGuid from './UsageGuid.vue';

@Component({
    name: 'ContentTitle',
    components: {
        UsageGuid,
    },
})
export default class ContentTitle extends Vue {
    @Prop(String) private contentTitle!: string;
    @Prop(String) private helpTitle!: string;
    @Prop({
        type: Boolean,
        default: false,
    })
    private goBack!: boolean; // 是否显示返回按钮；默认false
    @Prop({
        type: Boolean,
        default: false,
    })
    private hasGuid!: boolean; // 是否显示使用指南；默认false
    @Watch('goBack') // 监听父组件传过来的值
    private goBackFun(value: boolean) {
        this.goBack = value;
    }
    // 给父组件传值
    @Emit('goBackClick')
    private goBackTodo(): boolean {
        return true;
    }
    private goBackHandle() {
        this.goBackTodo();
    }
}
