
import { Prop, Vue, Component, Watch, Emit } from "vue-property-decorator";

@Component({
  name: "UE",
})
export default class UE extends Vue {
  @Prop(Object) private config?: object;
  @Prop(String) private id?: string;
  @Prop(String) private value?: string;
  @Prop(Boolean) private disabled?: boolean;
  private editor: any = null;
  @Watch('disabled')  // 监听编辑器禁用状态
  private disabledChange(val: boolean) {
    if (this.editor) {
      if (val) {  // 禁用
        this.editor.setDisabled('fullscreen');
      } else {  // 启用
        this.editor.setEnabled('fullscreen');
      }
    }
  }
  private mounted() {
    this.$nextTick( () => {
      this.editor = (window as any).UE.getEditor(this.id, this.config);
      console.log(this.editor);
      this.editor.addListener("ready", () => {
        if (this.disabled) {
          this.editor.setDisabled('fullscreen');
        }
        this.editor.setContent(this.value); // 确保UE加载完成后，放入内容。
        this.editor.addListener("contentChange", () => {
          this.$emit("ueditorContent", this.editor.getContent()); // 内容发生变化，触发input事件，此处是为了实现v-mode功能
          this.$emit("ueditorContentText", this.editor.getContentTxt()); // 内容发生变化，触发input事件
        });
      });
    });
  }
  private setUEContent(value: any) {
    this.editor.setContent(value);
  }
  private getUEContent() {
    return this.editor.getContent();
  }

  private getUEContentText() {
    return this.editor.getContentTxt();
  }

  private destroyed() {
    this.editor.destroy();
  }
}
