import { render, staticRenderFns } from "./ueditor.vue?vue&type=template&id=4001b8f0&scoped=true"
import script from "./ueditor.vue?vue&type=script&lang=ts"
export * from "./ueditor.vue?vue&type=script&lang=ts"
import style0 from "./ueditor.vue?vue&type=style&index=0&id=4001b8f0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4001b8f0",
  null
  
)

export default component.exports